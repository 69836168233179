import { Fragment } from 'react';
import {
	Button,
	Divider,
	Flex,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	Text
} from '@chakra-ui/react';
import { Luggage } from '../../../types/Flight';
import { LuLuggage, LuTrash2 } from 'react-icons/lu';

interface Props {
	luggage: any;
	setLuggage: any;
}

const LuggageForm: React.FC<Props> = ({ luggage, setLuggage }) => {
	const addLuggage = () => {
		const arr: Luggage[] = [...luggage];
		arr.push({
			weight: '',
			carryOn: true
		});
		setLuggage(arr);
	};
	const updateWeight = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		const arr: Luggage[] = [...luggage];
		arr[Number(name)].weight = value;
		setLuggage(arr);
	};
	const setCarryOn = (index: number, value: boolean) => {
		const arr: Luggage[] = [...luggage];
		arr[index].carryOn = value;
		setLuggage(arr);
	};
	const deleteLuggage = (index: number) => {
		const arr: Luggage[] = [...luggage];
		arr.splice(index, 1);
		setLuggage(arr);
	};
	return (
		<Fragment>
			<FormControl>
				{luggage.map((item: Luggage, index: number) => (
					<Fragment key={index}>
						<Flex gap={3}>
							<Flex alignItems={'start'} mr={3}>
								<Text fontSize={'md'}>{index + 1}.</Text>
							</Flex>
							<FormControl>
								<FormLabel>Waga</FormLabel>
								<InputGroup>
									<Input
										name={index + ''}
										value={item.weight + ''}
										onChange={updateWeight}
									/>
									<InputRightElement>kg</InputRightElement>
								</InputGroup>
							</FormControl>
							<FormControl flex={1}>
								<FormLabel textAlign={'center'}>Podręczny</FormLabel>
								<Flex height={'40px'} gap={1}>
									<Flex
										h={'full'}
										px={3}
										borderRadius={4}
										justifyContent={'center'}
										alignItems={'center'}
										border={'1px solid'}
										borderColor={'gray.300'}
										cursor={'pointer'}
										color={item.carryOn ? 'white' : 'black'}
										bg={item.carryOn ? 'blue.500' : 'white'}
										onClick={() => setCarryOn(index, true)}>
										TAK
									</Flex>
									<Flex
										h={'full'}
										px={3}
										borderRadius={4}
										justifyContent={'center'}
										alignItems={'center'}
										border={'1px solid'}
										borderColor={'gray.300'}
										cursor={'pointer'}
										color={!item.carryOn ? 'white' : 'black'}
										bg={!item.carryOn ? 'blue.500' : 'white'}
										onClick={() => setCarryOn(index, false)}>
										NIE
									</Flex>
								</Flex>
							</FormControl>
							<Flex ml={5} alignItems={'end'}>
								<Button colorScheme="red" onClick={() => deleteLuggage(index)}>
									<LuTrash2 />
								</Button>
							</Flex>
						</Flex>
						{index + 1 < luggage.length ? <Divider my={5} /> : ''}
					</Fragment>
				))}
				<Button
					leftIcon={<LuLuggage />}
					mt={luggage.length > 0 ? 5 : 0}
					onClick={addLuggage}
					w={'full'}>
					{luggage.length > 0 ? 'Dodaj kolejny bagaż' : 'Dodaj bagaż'}
				</Button>
			</FormControl>
		</Fragment>
	);
};

export default LuggageForm;
