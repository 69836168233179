import {
	Button,
	Flex,
	Popover,
	PopoverArrow,
	PopoverCloseButton,
	PopoverContent,
	PopoverTrigger,
	Text,
	VStack
} from '@chakra-ui/react';
import { LuBus, LuCheck } from 'react-icons/lu';
import { useState } from 'react';

interface VehiclesFilterProps {
	vehiclesList: any[];
	selectedVehicle: { id: string } | null;
	setSelectedVehicle: (vehicle: { id: string } | null) => void;
}

const VehiclesFilter = ({
	vehiclesList,
	selectedVehicle,
	setSelectedVehicle
}: VehiclesFilterProps) => {
	const handleSelectVehicle = (vehicleId: string) => {
		setSelectedVehicle({ id: vehicleId });
	};

	const deleteSelectedVehicle = () => {
		setSelectedVehicle(null);
	};

	return (
		<Popover>
			<PopoverTrigger>
				<Button size={'sm'} variant={selectedVehicle ? 'solid' : 'outline'}>
					<Flex gap={2}>
						<LuBus />
					</Flex>
				</Button>
			</PopoverTrigger>
			<PopoverContent>
				<PopoverArrow />
				<PopoverCloseButton />
				<VStack align="start" p={3} spacing={2} mt={5}>
					{vehiclesList.map((vehicle: any) => (
						<Flex
							alignItems={'center'}
							justifyContent={'space-between'}
							key={vehicle._id}
							onClick={() => handleSelectVehicle(vehicle._id)}
							p={2}
							borderRadius="md"
							cursor="pointer"
							width="100%"
							transition="background-color 0.2s"
							color={selectedVehicle?.id === vehicle._id ? 'white' : 'black'}
							bg={
								selectedVehicle?.id === vehicle._id ? 'blue.400' : 'transparent'
							}
							_hover={{
								bg:
									selectedVehicle?.id === vehicle._id ? 'blue.600' : 'gray.100'
							}}>
							<Flex alignItems={'center'} gap={1}>
								<LuBus />
								<Text fontSize="sm">{vehicle.registrationNo}</Text>
							</Flex>
							{selectedVehicle?.id === vehicle._id && <LuCheck />}
						</Flex>
					))}
					{selectedVehicle && (
						<Button
							onClick={deleteSelectedVehicle}
							variant={'solid'}
							colorScheme="gray"
							width={'100%'}>
							Usuń kierowcę
						</Button>
					)}
				</VStack>
			</PopoverContent>
		</Popover>
	);
};

export default VehiclesFilter;
