import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { IOption } from '../../types/Option';
import SelectOptions from '../controls/SelectOptions';
import { GET_DRIVERS_LIST } from '../../graphql/queries/GetDriversList';
import { User } from '../../types/User';

const role = 'driver';

interface Props {
	setValue: any;
}

const DriversDropdown: React.FC<Props> = ({ setValue }) => {
	const { loading, data, refetch } = useQuery(GET_DRIVERS_LIST, {
		variables: { role }
	});
	const [options, setOptions] = useState<IOption[]>([]);
	useEffect(() => {
		refetch();
	}, []);
	useEffect(() => {
		if (data) {
			const opt: IOption[] = [];
			data.getUsersByRole.forEach((el: User) =>
				opt.push({ label: el.Name, value: el._id })
			);
			setOptions(opt);
		}
	}, [data]);
	const onSelectItem = (el: IOption) => {
		setValue('driver', el.value);
	};
	return (
		<SelectOptions
			loading={loading}
			title={'Kierowca'}
			options={options}
			selectItem={onSelectItem}
		/>
	);
};

export default DriversDropdown;
