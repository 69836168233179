import { Fragment } from 'react';
import { FormControl, FormLabel, Textarea } from '@chakra-ui/react';

interface Props {
	register: any;
}

const NotesForm: React.FC<Props> = ({ register }) => {
	return (
		<Fragment>
			<FormControl>
				<FormLabel>Informacje dodatkowe</FormLabel>
				<Textarea {...register('notes')} />
			</FormControl>
		</Fragment>
	);
};

export default NotesForm;
