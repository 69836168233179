import { gql } from '@apollo/client';

export const UPDATE_AGENCY = gql`
	mutation updateAgency(
		$id: ID!
		$name: String!
		$nip: String
		$address: String
		$postalCode: String
		$city: String
		$country: String
		$phone: String
		$email: String
		$person: String
	) {
		updateAgency(
			id: $id
			input: {
				name: $name
				nip: $nip
				address: $address
				postalCode: $postalCode
				city: $city
				country: $country
				phone: $phone
				email: $email
				person: $person
			}
		)
	}
`;
