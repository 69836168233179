import React, { useRef, Fragment, useState } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import { Flex, FormControl, FormLabel, Input } from '@chakra-ui/react';

interface PlaceResult {
	address_components?: google.maps.GeocoderAddressComponent[];
	geometry?: google.maps.places.PlaceGeometry;
	name?: string;
	formatted_address?: string;
	phone_number?: string;
}

interface Props {
	register: any;
	setValue: any;
	type: string; //departure/arrival
}

const FlightAirport: React.FC<Props> = ({ register, setValue, type }) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const [autocomplete, setAutocomplete] =
		useState<google.maps.places.Autocomplete | null>(null);
	const onLoad = (autoC: google.maps.places.Autocomplete) => {
		setAutocomplete(autoC);
	};
	const onPlaceChanged = () => {
		if (autocomplete !== null) {
			const place: PlaceResult = autocomplete.getPlace();
			const country = place.address_components?.find((el: any) =>
				el.types.includes('country')
			)?.short_name;
			const address = place.formatted_address;
			const phone = place.phone_number;
			const name = place.name;
			const values = {
				code: '',
				country,
				address,
				name,
				phone
			};
			if (type === 'departure') setValue('airportFrom', values);
			else if (type === 'arrival') setValue('airportTo', values);
		} else {
			console.log('Autocomplete is not loaded yet!');
		}
	};
	return (
		<Fragment>
			<FormControl>
				<FormLabel>Lotnisko</FormLabel>
				<Autocomplete
					onLoad={onLoad}
					onPlaceChanged={onPlaceChanged}
					options={{
						types: ['airport']
					}}>
					<Input ref={inputRef} placeholder="Wybierz lotnisko" />
				</Autocomplete>
			</FormControl>
			<FormControl mt={3}>
				<FormLabel>Data</FormLabel>
				<Flex gap={3}>
					<Input type="date" {...register(`${type}Date`)} />
					<Input type="time" {...register(`${type}Time`)} />
				</Flex>
			</FormControl>
		</Fragment>
	);
};

export default FlightAirport;
