import { FormControl, FormLabel, Skeleton } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { IOption } from '../../types/Option';

interface IProps {
	loading: boolean;
	title: string;
	options: IOption[];
	selectItem: (el: any) => any;
	isRequired?: boolean;
	defaultValue?: IOption | null;
}

const SelectOptions: React.FC<IProps> = ({
	loading,
	title,
	options,
	selectItem,
	isRequired = false,
	defaultValue
}) => {
	return (
		<FormControl isRequired={isRequired}>
			<FormLabel>{title}</FormLabel>
			<Skeleton isLoaded={!loading}>
				<Select
					useBasicStyles
					options={options}
					onChange={selectItem}
					placeholder="Wybierz"
					defaultValue={defaultValue}
					value={defaultValue}
				/>
			</Skeleton>
		</FormControl>
	);
};

export default SelectOptions;
