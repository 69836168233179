import { useState, useEffect } from 'react';
import { GET_AGENCY_LIST } from '../../graphql/queries/GetAgencyList';
import { useQuery } from '@apollo/client';
import { Agency } from '../../types/Agency';
import { IOption } from '../../types/Option';
import SelectOptions from '../controls/SelectOptions';

interface Props {
	setValue: any;
}

const AgencyDropdown: React.FC<Props> = ({ setValue }) => {
	const { loading, error, data, refetch } = useQuery(GET_AGENCY_LIST);
	const [options, setOptions] = useState<IOption[]>([]);
	useEffect(() => {
		refetch();
	}, []);
	useEffect(() => {
		if (data) {
			const opt: IOption[] = [];
			data.agencyList.forEach((el: Agency) =>
				opt.push({ label: el.name, value: el._id })
			);
			setOptions(opt);
		}
	}, [data]);
	const onSelectItem = (el: IOption) => {
		setValue('agency', el.value);
	};
	return (
		<SelectOptions
			loading={loading}
			title={'Biuro podróży'}
			options={options}
			selectItem={onSelectItem}
		/>
	);
};

export default AgencyDropdown;
