import { gql } from '@apollo/client';

export const GET_FLIGHTS = gql`
	query flights {
		flights {
			_id
			airLine
			airportFrom {
				name
				country
			}
			departureDate
			departureTime
			airportTo {
				name
				country
			}
			arrivalDate
			arrivalTime
			userID {
				firstName
				lastName
			}
		}
	}
`;
