import { gql } from '@apollo/client';

export const ADD_AGENCY = gql`
	mutation createAgency(
		$name: String!
		$nip: String
		$address: String
		$postalCode: String
		$city: String
		$country: String
		$phone: String
		$email: String
		$person: String
	) {
		createAgency(
			input: {
				name: $name
				nip: $nip
				address: $address
				postalCode: $postalCode
				city: $city
				country: $country
				phone: $phone
				email: $email
				person: $person
			}
		) {
			id
		}
	}
`;
