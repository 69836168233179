import React from 'react';
import { Card, CardBody, Flex, Text } from '@chakra-ui/react';
import { LuLock } from 'react-icons/lu';

const FormLayout = ({
	children,
	title,
	subTitle,
	isDisabled,
	fields
}: {
	children: React.ReactNode | null | undefined;
	title: string;
	subTitle?: string;
	isDisabled?: boolean;
	fields?: string;
}) => {
	return (
		<Flex flexFlow={{ base: 'column', md: 'row' }} mb={4}>
			<Flex flexFlow={'column'} flex={1} pb={4}>
				<Text fontSize={'lg'} fontWeight={600}>
					{title}
				</Text>
				<Text fontSize={'sm'} color={'gray.600'} mt={1}>
					{subTitle}
				</Text>
			</Flex>
			<Flex flex={3}>
				<Card width={'full'}>
					{isDisabled ? (
						<Flex
							w={'full'}
							h={'full'}
							position={'absolute'}
							color={'white'}
							bg={'gray.500'}
							alignItems={'center'}
							justifyContent={'center'}
							borderRadius={5}>
							<Flex
								gap={2}
								flexFlow={'column'}
								alignItems={'center'}
								justifyContent={'center'}>
								<Text textAlign={'center'} fontSize={'3xl'} fontWeight={700}>
									<LuLock />
								</Text>
								<Text fontWeight={'bold'} textAlign={'center'}>
									Uzupełnij pola
									<br /> <i>{fields}</i>
								</Text>
							</Flex>
						</Flex>
					) : (
						''
					)}
					<CardBody zIndex={isDisabled ? -1 : 1}>{children}</CardBody>
				</Card>
			</Flex>
		</Flex>
	);
};

export default FormLayout;
