import React, { useEffect, useState } from 'react';
import { AirLines } from '../../assets/objects/airlines';
import SelectOptions from '../controls/SelectOptions';
import { IOption } from '../../types/Option';

interface Props {
	setValue: (value: string) => any;
	defaultValue?: string;
}

const AirLinesDropdown: React.FC<Props> = ({ setValue, defaultValue }) => {
	const [loading, setLoading] = useState<boolean>(true);
	const [options, setOptions] = useState<IOption[]>([]);
	const [selectedValue, setSelectedValue] = useState<IOption | null>(null);
	const onSelectItem = (el: IOption) => {
		setValue(el.value);
		setSelectedValue(el);
	};
	useEffect(() => {
		const opt: IOption[] = [];
		AirLines.forEach((e: string) => {
			opt.push({ label: e, value: e });
			if (e === defaultValue) setSelectedValue({ label: e, value: e });
		});
		setOptions(opt);
		setLoading(false);
	}, []);
	return (
		<SelectOptions
			loading={loading}
			title={'Linia lotnicza'}
			options={options}
			selectItem={onSelectItem}
			defaultValue={selectedValue}
		/>
	);
};

export default AirLinesDropdown;
