import { Flex, Text } from '@chakra-ui/react';
import { LuBuilding2, LuBus, LuMapPin, LuUser } from 'react-icons/lu';

interface Props {
	trip: any;
	onSelect: (tripID: string, driver: string | null) => any;
}

const SelectTripResultItem: React.FC<Props> = ({ trip, onSelect }) => {
	const selectItem = () => {
		if (trip.driver) onSelect(trip._id, trip.driver._id);
		else onSelect(trip._id, null);
	};
	return (
		<Flex
			border={'1px solid'}
			borderColor={'gray.300'}
			borderRadius={4}
			p={3}
			flexFlow={'column'}
			cursor={'pointer'}
			bg={'gray.50'}
			boxShadow="sm"
			transition="box-shadow 0.2s"
			_hover={{
				boxShadow: 'xl'
			}}
			onClick={selectItem}>
			<Flex alignItems={'center'} gap={2}>
				<Text color={'gray.600'}>
					<LuMapPin />
				</Text>
				<Text fontWeight={500}>{trip.name}</Text>
			</Flex>
			<Flex alignItems={'center'} gap={2}>
				<Text color={'gray.600'}>
					<LuBuilding2 />
				</Text>
				<Text fontWeight={500}>
					{trip.agency ? trip.agency.name : 'Brak danych'}
				</Text>
			</Flex>
			<Flex alignItems={'center'} gap={2}>
				<Text color={'gray.600'}>
					<LuBus />
				</Text>
				<Text fontWeight={500}>
					{trip.car ? trip.car.registrationNo : 'Brak danych'}
				</Text>
			</Flex>
			<Flex alignItems={'center'} gap={2}>
				<Text color={'gray.600'}>
					<LuUser />
				</Text>
				<Text fontWeight={500}>
					{trip.driver
						? `${trip.driver.firstName} ${trip.driver.lastName}`
						: 'Brak danych'}
				</Text>
			</Flex>
		</Flex>
	);
};

export default SelectTripResultItem;
