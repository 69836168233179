import React, { useEffect, useState } from 'react';
import SelectOptions from '../controls/SelectOptions';
import { IOption } from '../../types/Option';
import { useQuery } from '@apollo/client';
import { GET_CAR_LIST } from '../../graphql/queries/GetCarList';
import { Vehicle } from '../../types/Vehicle';

interface Props {
	setValue: any;
}

const VehiclesDropdown: React.FC<Props> = ({ setValue }) => {
	const { loading, error, data, refetch } = useQuery(GET_CAR_LIST);
	const [options, setOptions] = useState<IOption[]>([]);
	useEffect(() => {
		refetch();
	}, []);
	useEffect(() => {
		if (data) {
			const opt: IOption[] = [];
			data.carsList.forEach((el: Vehicle) =>
				opt.push({ label: el.registrationNo, value: el._id })
			);
			setOptions(opt);
		}
	}, [data]);
	const onSelectItem = (el: IOption) => {
		setValue('vehicle', el.value);
	};
	return (
		<SelectOptions
			loading={loading}
			title={'Pojazd'}
			options={options}
			selectItem={onSelectItem}
		/>
	);
};

export default VehiclesDropdown;
