import { gql } from '@apollo/client';

export const ADD_FLIGHT = gql`
	mutation addFlight(
		$userID: ID!
		$tripID: ID
		$flightNumber: String
		$airLine: String
		$departureDate: String
		$arrivalDate: String
		$departureTime: String
		$arrivalTime: String
		$airportFrom: AirportInput
		$airportTo: AirportInput
		$luggage: [LuggageInput]
		$checkedIn: Boolean
		$checkedInDate: String
		$notes: String
		$boardingPass: String
	) {
		addFlight(
			input: {
				userID: $userID
				tripID: $tripID
				flightNumber: $flightNumber
				airLine: $airLine
				departureDate: $departureDate
				arrivalDate: $arrivalDate
				departureTime: $departureTime
				arrivalTime: $arrivalTime
				airportFrom: $airportFrom
				airportTo: $airportTo
				luggage: $luggage
				checkedIn: $checkedIn
				checkedInDate: $checkedInDate
				notes: $notes
				boardingPass: $boardingPass
			}
		) {
			_id
		}
	}
`;
