import {
	Button,
	Flex,
	Popover,
	PopoverContent,
	PopoverTrigger,
	Box,
	Text,
	VStack,
	PopoverArrow,
	PopoverCloseButton
} from '@chakra-ui/react';
import { LuCheck, LuUser } from 'react-icons/lu';
import { useState } from 'react';

interface DriversFilterProps {
	driversList: any[];
	selectedDriver: { id: string } | null;
	setSelectedDriver: (driver: { id: string } | null) => void;
}

const DriversFilter = ({
	driversList,
	selectedDriver,
	setSelectedDriver
}: DriversFilterProps) => {
	const handleSelectDriver = (driverId: string) => {
		setSelectedDriver({ id: driverId });
	};

	const deleteSelectedDriver = () => {
		setSelectedDriver(null);
	};

	return (
		<Popover>
			<PopoverTrigger>
				<Button size={'sm'} variant={selectedDriver ? 'solid' : 'outline'}>
					<Flex gap={2}>
						<LuUser />
					</Flex>
				</Button>
			</PopoverTrigger>
			<PopoverContent>
				<PopoverArrow />
				<PopoverCloseButton />
				<VStack align="start" p={3} spacing={2} mt={5}>
					{driversList.map((driver: any) => (
						<Flex
							alignItems={'center'}
							justifyContent={'space-between'}
							key={driver._id}
							onClick={() => handleSelectDriver(driver._id)}
							p={2}
							borderRadius="md"
							cursor="pointer"
							width="100%"
							transition="background-color 0.2s"
							color={selectedDriver?.id === driver._id ? 'white' : 'black'}
							bg={
								selectedDriver?.id === driver._id ? 'blue.400' : 'transparent'
							}
							_hover={{
								bg: selectedDriver?.id === driver._id ? 'blue.600' : 'gray.100'
							}}>
							<Flex alignItems={'center'} gap={1}>
								<LuUser />
								<Text fontSize="sm">
									{driver.firstName} {driver.lastName}
								</Text>
							</Flex>
							{selectedDriver?.id === driver._id && <LuCheck />}
						</Flex>
					))}
					{selectedDriver && (
						<Button
							onClick={deleteSelectedDriver}
							variant={'solid'}
							colorScheme="gray"
							width={'100%'}>
							Usuń kierowcę
						</Button>
					)}
				</VStack>
			</PopoverContent>
		</Popover>
	);
};

export default DriversFilter;
