import {
	Button,
	Container,
	Skeleton,
	useDisclosure,
	useToast
} from '@chakra-ui/react';
import TableComponent from '../../components/table';
import { GET_CAR_LIST } from '../../graphql/queries/GetCarList';
import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import AlertWindow from '../../components/alert';
import { useNavigate } from 'react-router-dom';
import { DELETE_CAR } from '../../graphql/mutations/DeleteCar';
import PageHeader from '../../components/pageHeader';
import { LuPlus } from 'react-icons/lu';
import Loading from '../../components/loading/Loading';

const columns = [
	{ name: 'Rejestracja', key: 'registrationNo' },
	{ name: 'Marka', key: 'brand' },
	{ name: 'Model', key: 'carModel' }
	// { name: 'Siedzenia', key: 'seats' }
	// { name: 'Kolejny wyjazd', key: 'next' },
	// { name: 'kraj', key: 'city' }
];

const FleetList = () => {
	const [itemToDelete, setItemToDelete] = useState<any>();
	const [subTitle, setSubTitle] = useState('');
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { loading, data, refetch } = useQuery(GET_CAR_LIST);
	const [deleteCar] = useMutation(DELETE_CAR);
	const history = useNavigate();
	const toast = useToast();
	const openEdit = (el: any) => {
		history(`/fleet/edit/${el.id}`);
	};
	const deleteItem = (el: any) => {
		setSubTitle(`Czy napewno chcesz usunąć pojazd ${el.registrationNo}?`);
		setItemToDelete(el);
		onOpen();
	};
	const onDelete = async () => {
		const id = itemToDelete.id;
		await deleteCar({
			variables: {
				id
			}
		});
		onClose();
		refetch();
		toast({
			title: 'Sukces!',
			description: 'Pojazd został usunięty.',
			status: 'success',
			duration: 3000,
			isClosable: true,
			position: 'bottom-right'
		});
	};
	useEffect(() => {
		refetch();
	}, []);
	useEffect(() => {
		setItemToDelete(null);
	}, [onClose]);
	const openDetails = (el: any) => {
		history(`/fleet/${el.id}`);
	};
	const createNew = () => {
		history(`/fleet/new`);
	};
	return (
		<Container maxW={'5xl'}>
			<PageHeader
				title="Flota"
				actions={
					<Button leftIcon={<LuPlus />} colorScheme="blue" onClick={createNew}>
						Dodaj
					</Button>
				}
			/>
			<Skeleton isLoaded={!loading} height="80px">
				{loading ? (
					<Loading />
				) : (
					<TableComponent
						header={'Pojazdy'}
						columns={columns}
						data={data.carsList}
						onEdit={openEdit}
						onDelete={deleteItem}
						onOpen={openDetails}
					/>
				)}
			</Skeleton>
			<AlertWindow
				isOpen={isOpen}
				onOpen={onOpen}
				onClose={onClose}
				title={'Usuń pojazd'}
				subTitle={subTitle}
				onDelete={onDelete}
			/>
		</Container>
	);
};

export default FleetList;
