import {
	Box,
	Button,
	Container,
	Flex,
	Skeleton,
	useToast
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { GET_TRIP_LIST } from '../../graphql/queries/GetTripList';
import TableComponent from '../../components/table';
import Loading from '../../components/loading/Loading';
import PageHeader from '../../components/pageHeader';
import { LuPlus } from 'react-icons/lu';
import TableStatus from '../../components/trip/tableStatus';
import TableRequired from '../../components/trip/tableRequired';
import { parseDate } from '../../utils/parseDate';
import TripDriverList from '../../components/trip/driverList';
import TripVehicleList from '../../components/trip/vehicleList';
import {
	UPDATE_TRIP_DRIVER,
	UPDATE_TRIP_VEHICLE
} from '../../graphql/mutations/UpdateTrip_DriverVehicle';
import { StatusesEnum } from '../../enums/Statuses';
import getTripStatus from '../../utils/getTripStatus';
import { ITripData } from '../../types/ITrip';
import DateRangeButton from '../../components/dateRange';
import DriversFilter from '../../components/driversFilter';
import VehiclesFilter from '../../components/vehiclesFilter';

const columns = [
	{ name: '', key: 'status', customField: true },
	{ name: 'Nazwa', key: 'name' },
	{ name: 'Pojazd', key: 'car', customField: true },
	{ name: 'Kierowca', key: 'driver', customField: true },
	{ name: 'Biuro', key: 'agency' },
	{ name: 'Od', key: 'dateFrom' },
	{ name: 'Do', key: 'dateTo' }
];

interface RefObject {
	getList: () => any;
}

const TripList = () => {
	const toast = useToast();
	const refDrivers = useRef<RefObject>(null);
	const refVehicles = useRef<RefObject>(null);
	const [selectedDriver, setSelectedDriver] = useState<{ id: string } | null>(
		null
	);
	const [selectedVehicle, setSelectedVehicle] = useState<{ id: string } | null>(
		null
	);
	const [driversList, setDriversList] = useState<any[]>([]);
	const [selectedDriverFilter, setSelectedDriverFilter] = useState<{
		id: string;
	} | null>(null);
	const [selectedVehicleFilter, setSelectedVehicleFilter] = useState<{
		id: string;
	} | null>(null);
	const [vehiclesList, setVehiclesList] = useState<any[]>([]);
	const [formattedData, setFormattedData] = useState<any[]>([]);
	const [dateRange, setDateRange] = useState({
		start: new Date(Date.now() - 2 * 7 * 24 * 60 * 60 * 1000), // domyślnie 2 tygodnie wstecz
		end: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000) // domyślnie miesiąc do przodu
	});
	const history = useNavigate();
	const { loading, data, refetch } = useQuery(GET_TRIP_LIST);
	const [updateTripDriver] = useMutation(UPDATE_TRIP_DRIVER);
	const [updateTripVehicle] = useMutation(UPDATE_TRIP_VEHICLE);

	useEffect(() => {
		refetch();
	}, []);

	useEffect(() => {
		if (data) {
			const arr: any[] = [];
			const _drivers: any[] = [];
			const _vehicles: any[] = [];
			const _driversID: string[] = [];
			const _vehiclesID: string[] = [];

			const filteredData = data.tripList.filter((item: any) => {
				const dateFrom = new Date(parseInt(item.dateFrom, 10));
				const dateTo = new Date(parseInt(item.dateTo, 10));

				if (item.driver?._id && !_driversID.includes(item.driver._id)) {
					_driversID.push(item.driver._id);
					_drivers.push(item.driver);
				}

				if (item.car?._id && !_vehiclesID.includes(item.car._id)) {
					_vehiclesID.push(item.car._id);
					_vehicles.push(item.car);
				}

				// Filtracja po wybranym kierowcy
				if (
					selectedDriverFilter &&
					item.driver?._id !== selectedDriverFilter.id
				) {
					return false; // Jeśli kierowca nie pasuje, nie dodajemy tego elementu
				}

				// Filtracja po wybranym pojeździe
				if (
					selectedVehicleFilter &&
					item.vehicle?._id !== selectedVehicleFilter.id
				) {
					return false; // Jeśli pojazd nie pasuje, nie dodajemy tego elementu
				}

				return dateTo >= dateRange.start && dateFrom <= dateRange.end;
			});
			setDriversList(_drivers);
			setVehiclesList(_vehicles);
			filteredData.forEach((el: ITripData) => {
				const dateFrom: string = parseDate(el.dateFrom);
				const dateTo: string = parseDate(el.dateTo);
				const status: StatusesEnum = getTripStatus(el);
				const item = {
					id: el._id,
					name: el.name,
					car: (
						<TableRequired
							item={el}
							value={el.car ? `${el.car.registrationNo}` : null}
							title={'Pojazd'}
							onClear={clearSelected}
							onSave={saveVehicle}
							element={
								<TripVehicleList
									ref={refVehicles}
									dateFrom={formatDate(dateFrom)}
									dateTo={formatDate(dateTo)}
									onSelect={onSetVehicle}
									activeItem={selectedVehicle}
								/>
							}
						/>
					),
					driver: (
						<TableRequired
							item={el}
							value={
								el.driver
									? `${el.driver.firstName + ' ' + el.driver.lastName}`
									: null
							}
							title={'Kierowca'}
							onClear={clearSelected}
							onSave={saveDriver}
							element={
								<TripDriverList
									ref={refDrivers}
									dateFrom={formatDate(dateFrom)}
									dateTo={formatDate(dateTo)}
									onSelect={onSetDriver}
									activeItem={selectedDriver}
								/>
							}
						/>
					),
					agency: el.agency.name,
					dateFrom: dateFrom,
					dateTo: dateTo,
					status: (
						<TableStatus tripID={el._id} status={status} onConfirm={refetch} />
					)
				};
				arr.push(item);
			});
			setFormattedData(arr);
		}
	}, [
		data,
		dateRange,
		selectedDriver,
		selectedDriverFilter,
		selectedVehicleFilter
	]);

	const formatDate = (inputDate: string): string => {
		const parts = inputDate.split('.');
		return `${parts[2]}-${parts[1]}-${parts[0]}`;
	};

	const clearSelected = () => {
		setSelectedDriver(null);
		setSelectedVehicle(null);
	};

	const onSetDriver = (id: string | null) => {
		setSelectedDriver(id ? { id } : null);
	};

	const saveDriver = async (el: any) => {
		if (refDrivers.current) {
			const driver = refDrivers.current.getList().id;
			await updateTripDriver({
				variables: { tripID: el._id, driver }
			});
			refetch();
			toast({
				title: 'Sukces!',
				description: 'Wyjazd został zaktualizowany!',
				status: 'success',
				duration: 3000,
				isClosable: true,
				position: 'bottom-right'
			});
		}
	};

	const onSetVehicle = (id: string | null) => {
		setSelectedVehicle(id ? { id } : null);
	};

	const saveVehicle = async (el: any) => {
		if (refVehicles.current) {
			const car = refVehicles.current.getList().id;
			await updateTripVehicle({
				variables: { tripID: el._id, car }
			});
			refetch();
			toast({
				title: 'Sukces!',
				description: 'Wyjazd został zaktualizowany!',
				status: 'success',
				duration: 3000,
				isClosable: true,
				position: 'bottom-right'
			});
		}
	};

	const createNew = () => {
		history(`/trip/new`);
	};

	const openDetails = (el: any) => {
		history(`/trip/${el.id}`);
	};

	return (
		<Container maxW={'6xl'}>
			<PageHeader
				title="Wyjazdy"
				actions={
					<Button leftIcon={<LuPlus />} colorScheme="blue" onClick={createNew}>
						Dodaj
					</Button>
				}
			/>
			<Flex mt={8} justifyContent={'flex-end'} gap={3}>
				<DriversFilter
					driversList={driversList}
					selectedDriver={selectedDriverFilter}
					setSelectedDriver={setSelectedDriverFilter}
				/>
				<VehiclesFilter
					vehiclesList={vehiclesList}
					selectedVehicle={selectedVehicleFilter}
					setSelectedVehicle={setSelectedVehicleFilter}
				/>
				<DateRangeButton defaultRange={dateRange} onChange={setDateRange} />
			</Flex>
			<Skeleton isLoaded={!loading} height="80px">
				{loading ? (
					<Loading />
				) : (
					<Box pb={'24px'}>
						<TableComponent
							header={'Wyjazdy'}
							columns={columns}
							data={formattedData}
							onOpen={openDetails}
						/>
					</Box>
				)}
			</Skeleton>
		</Container>
	);
};

export default TripList;
