import { useQuery } from '@apollo/client';
import { GET_USERS } from '../../graphql/queries/GetUsers';
import { useState, useEffect } from 'react';
import { IOption } from '../../types/Option';
import { User } from '../../types/User';
import SelectOptions from '../controls/SelectOptions';

interface Props {
	setValue: (value: string) => any;
	defaultValue?: string;
}

const UsersDropdown: React.FC<Props> = ({ setValue, defaultValue }) => {
	const { loading, data, refetch } = useQuery(GET_USERS);
	const [options, setOptions] = useState<IOption[]>([]);
	const [selectedValue, setSelectedValue] = useState<IOption | null>(null);
	useEffect(() => {
		refetch();
	}, []);
	useEffect(() => {
		if (data) {
			const opt: IOption[] = [];
			data.getUsers.forEach((el: User) => {
				opt.push({ label: el.Name, value: el._id });
				if (el._id === defaultValue)
					setSelectedValue({ label: el.Name, value: el._id });
			});
			setOptions(opt);
		}
	}, [data]);
	useEffect(() => {
		if (data) {
			const item = data.getUsers.find((el: User) => el._id === defaultValue);
			if (item) setSelectedValue({ label: item.Name, value: item._id });
		}
	}, [defaultValue]);
	const onSelectItem = (el: IOption) => {
		setValue(el.value);
	};
	return (
		<SelectOptions
			loading={loading}
			title={'Pasażer'}
			options={options}
			selectItem={onSelectItem}
			defaultValue={selectedValue}
		/>
	);
};

export default UsersDropdown;
