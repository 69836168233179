import {
	AlertDialog,
	AlertDialogBody,
	AlertDialogCloseButton,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogOverlay,
	Button,
	Flex,
	FormControl,
	FormLabel,
	Input,
	useDisclosure
} from '@chakra-ui/react';
import UsersDropdown from '../../dropdowns/UsersDropdown';
import AirLinesDropdown from '../../dropdowns/AirlinesDropdown';
import { LuSearch } from 'react-icons/lu';
import { Fragment, useState } from 'react';
import SelectTrip from '../../drawers/SelectTrip';
import React from 'react';

interface Props {
	register: any;
	setValue: any;
	watch: any;
}

const FlightForm: React.FC<Props> = ({ register, setValue, watch }) => {
	const [driverTrip, setDriverTrip] = useState<string | null>(null);
	const {
		isOpen: isOpenAlert,
		onOpen: onOpenAlert,
		onClose: onCloseAlert
	} = useDisclosure();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const cancelRef = React.useRef<HTMLButtonElement>(null);
	const onSelectTrip = (tripID: string, driverID?: string) => {
		setValue('tripID', tripID);
		onClose();
		if (driverID) {
			setDriverTrip(driverID);
			onOpenAlert();
		}
	};
	const onSelectUser = (userID: string) => {
		setValue('userID', userID);
	};
	const setDriver = () => {
		if (driverTrip) {
			setValue('userID', driverTrip);
			onCloseAlert();
		}
	};
	const setAirLine = (value: string) => {
		setValue('airLine', value);
	};
	return (
		<Fragment>
			<Flex flexFlow={'column'} gap={5}>
				<FormControl>
					<FormLabel>Wyjazd (ID)</FormLabel>
					<Flex gap={3}>
						<Input disabled {...register('tripID')} />
						<Button onClick={onOpen} leftIcon={<LuSearch />}>
							Znajdź
						</Button>
					</Flex>
				</FormControl>
				<UsersDropdown setValue={onSelectUser} defaultValue={watch('userID')} />
				<AirLinesDropdown
					setValue={setAirLine}
					defaultValue={watch('airLine')}
				/>
				<FormControl>
					<FormLabel>Numer lotu</FormLabel>
					<Input {...register('flightNumber')} />
				</FormControl>
			</Flex>
			<SelectTrip onClose={onClose} isOpen={isOpen} onSelect={onSelectTrip} />
			<AlertDialog
				motionPreset="slideInBottom"
				leastDestructiveRef={cancelRef}
				onClose={onCloseAlert}
				isOpen={isOpenAlert}
				isCentered>
				<AlertDialogOverlay />
				<AlertDialogContent>
					<AlertDialogHeader>Uzupełnić pasżera?</AlertDialogHeader>
					<AlertDialogCloseButton />
					<AlertDialogBody>
						Ustaw kierowce przypisanego do wyjazdu jako pasżera
					</AlertDialogBody>
					<AlertDialogFooter>
						<Button ref={cancelRef} onClick={onCloseAlert}>
							Nie
						</Button>
						<Button colorScheme="blue" ml={3} onClick={setDriver}>
							Tak
						</Button>
					</AlertDialogFooter>
				</AlertDialogContent>
			</AlertDialog>
		</Fragment>
	);
};

export default FlightForm;
