import { Button, Flex, FormControl, FormLabel, Input } from '@chakra-ui/react';
import AgencyDropdown from '../dropdowns/AgencyDropdown';
import DriversDropdown from '../dropdowns/DriversDropdown';
import { LuSearch } from 'react-icons/lu';
import VehiclesDropdown from '../dropdowns/VehiclesDropdown';
import { Fragment } from 'react';

interface Props {
	search: () => any;
	filtersValue: any;
	setFiltersValue: any;
}

const SelectTripFilters: React.FC<Props> = ({
	search,
	filtersValue,
	setFiltersValue
}) => {
	const setValue = (title: string, value: string) => {
		let obj = { ...filtersValue };
		switch (title) {
			case 'agency':
				setFiltersValue({ ...obj, ...{ agencyID: value } });
				break;
			case 'vehicle':
				setFiltersValue({ ...obj, ...{ carID: value } });
				break;
			case 'driver':
				setFiltersValue({ ...obj, ...{ driverID: value } });
				break;

			default:
				break;
		}
	};
	const setDateFrom = (e: React.ChangeEvent<HTMLInputElement>) => {
		let obj = { ...filtersValue };
		setFiltersValue({ ...obj, ...{ dateFrom: e.target.value } });
	};
	const setDateTo = (e: React.ChangeEvent<HTMLInputElement>) => {
		let obj = { ...filtersValue };
		setFiltersValue({ ...obj, ...{ dateTo: e.target.value } });
	};
	return (
		<Fragment>
			<Flex flexFlow={'column'} gap={3}>
				<AgencyDropdown setValue={setValue} />
				<DriversDropdown setValue={setValue} />
				<VehiclesDropdown setValue={setValue} />
				<FormControl>
					<FormLabel>Data od</FormLabel>
					<Input type="date" onChange={setDateFrom} />
				</FormControl>
				<FormControl>
					<FormLabel>Data do</FormLabel>
					<Input type="date" onChange={setDateTo} />
				</FormControl>
				<Button onClick={search} leftIcon={<LuSearch />}>
					Szukaj
				</Button>
			</Flex>
		</Fragment>
	);
};

export default SelectTripFilters;
