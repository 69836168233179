import React, { useState, useEffect } from 'react';
import {
	Button,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverArrow,
	PopoverCloseButton,
	Box,
	Text,
	Stack,
	Flex
} from '@chakra-ui/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Stylowanie datepickera
import { LuCalendar } from 'react-icons/lu';

interface DateRangeButtonProps {
	defaultRange: { start: Date; end: Date }; // Oczekiwany typ dla defaultRange
	onChange: (range: { start: Date; end: Date }) => void; // Funkcja callback dla zmiany dat
}

const DateRangeButton: React.FC<DateRangeButtonProps> = ({
	defaultRange,
	onChange
}) => {
	// Allowing `null` as an initial state
	const [startDate, setStartDate] = useState<Date | null>(
		defaultRange.start || null
	);
	const [endDate, setEndDate] = useState<Date | null>(defaultRange.end || null);

	// Użyj useEffect, aby aktualizować daty na zewnątrz, gdy się zmienią
	useEffect(() => {
		// Only update onChange when both dates are set (not null)
		if (startDate && endDate) {
			onChange({ start: startDate, end: endDate });
		}
	}, [startDate, endDate, onChange]);

	const handleStartDateChange = (date: Date | null) => {
		setStartDate(date); // This can be either a Date or null
	};

	const handleEndDateChange = (date: Date | null) => {
		setEndDate(date); // This can be either a Date or null
	};

	const formatDate = (date: Date | null) => {
		return date ? date.toLocaleDateString() : 'Select Date';
	};

	const handleConfirm = () => {
		if (startDate && endDate) {
			alert(
				`Selected Date Range:\nFrom: ${formatDate(startDate)}\nTo: ${formatDate(endDate)}`
			);
		} else {
			alert('Please select both start and end dates.');
		}
	};

	return (
		<Popover>
			<PopoverTrigger>
				<Button colorScheme="gray" variant="solid" size={'sm'}>
					<Flex gap={2}>
						<LuCalendar />
						{startDate && endDate
							? `${formatDate(startDate)} - ${formatDate(endDate)}`
							: 'Wybierz zakres dat'}
					</Flex>
				</Button>
			</PopoverTrigger>
			<PopoverContent>
				<PopoverArrow />
				<PopoverCloseButton />
				<Box p="4">
					<Text mb="2" fontWeight="bold">
						Data od:
					</Text>
					<DatePicker
						selected={startDate}
						onChange={handleStartDateChange}
						selectsStart
						startDate={startDate || undefined} // Pass undefined if null
						endDate={endDate || undefined} // Pass undefined if null
						dateFormat="dd/MM/yyyy"
						placeholderText="Data od"
						className="custom-datepicker"
					/>
					<Text mt="4" mb="2" fontWeight="bold">
						Data do:
					</Text>
					<DatePicker
						selected={endDate}
						onChange={handleEndDateChange}
						selectsEnd
						startDate={startDate || undefined} // Pass undefined if null
						endDate={endDate || undefined} // Pass undefined if null
						minDate={startDate || undefined} // Pass undefined if null
						dateFormat="dd/MM/yyyy"
						placeholderText="Data do"
						className="custom-datepicker"
					/>
				</Box>
			</PopoverContent>
		</Popover>
	);
};

export default DateRangeButton;
