// Login.tsx
import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
	Button,
	Flex,
	FormControl,
	FormLabel,
	Heading,
	Hide,
	Image,
	Input,
	Text
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Navigate, useParams } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { LOGIN_MUTATION } from '../graphql/mutations/Login';
import loginScreen from '../assets/loginscreen.png';

const schema = yup
	.object({
		code: yup.string().required('Pole nie moe być puste!'),
		username: yup.string().required('Pole nie moe być puste!'),
		password: yup.string().required('Pole nie moe być puste!')
	})
	.required();

const Login: React.FC = () => {
	const [loginCode, setLoginCode] = useState<number>(0);
	const params = useParams();
	const code = params.code;
	const [loginMutation] = useMutation(LOGIN_MUTATION);
	const { handleSubmit, register, setValue } = useForm({
		resolver: yupResolver(schema)
	});
	const { login, isAuthenticated } = useAuth();
	const handleLogin = async ({
		code,
		username,
		password
	}: {
		code: string;
		username: string;
		password: string;
	}) => {
		try {
			const { data } = await loginMutation({
				variables: { code, username, password }
			});
			const token = data.login.token;
			login(token);
		} catch (error) {
			console.error('Login failed:', error);
		}
	};
	useEffect(() => {
		if (code) {
			setLoginCode(+code);
			setValue('code', code);
		}
	}, [code]);
	if (isAuthenticated) return <Navigate to={'/'} />;
	return (
		<Flex>
			<Hide below="lg">
				<Flex flex={1} height={'100vh'} bg={'transparent'}>
					<Image
						width={'100%'}
						height={'100%'}
						objectFit={'cover'}
						src={loginScreen}
					/>
				</Flex>
			</Hide>
			<Flex
				flex={{ base: 1, '2xl': 2 }}
				justifyContent={'center'}
				alignItems={'center'}>
				<Flex flexFlow={'column'} minW={'lg'} p={8}>
					<Heading>LOGOWANIE</Heading>
					<Text color={'gray.500'} mt={2}>
						LogicTransport
					</Text>
					{/* {error && <p>{error.message}</p>} */}
					<form onSubmit={handleSubmit(handleLogin)}>
						{loginCode ? (
							''
						) : (
							<FormControl mt={6} isRequired>
								<FormLabel>Kod Firmy</FormLabel>
								<Input {...register('code')} size={'lg'} />
							</FormControl>
						)}
						<FormControl mt={6} isRequired>
							<FormLabel>E-mail lub nazwa uzytkownika</FormLabel>
							<Input {...register('username')} size={'lg'} />
						</FormControl>
						<FormControl mt={4} isRequired>
							<FormLabel>Hasło</FormLabel>
							<Input type="password" {...register('password')} size={'lg'} />
						</FormControl>
						<Button
							type="submit"
							colorScheme={'blue'}
							w="full"
							mt={6}
							size={'lg'}>
							ZALOGUJ
						</Button>
					</form>
				</Flex>
			</Flex>
		</Flex>
	);
};

export default Login;
