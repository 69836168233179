import { Fragment, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { GET_TRIP_LIST } from '../../graphql/queries/GetTripList';
import { Flex } from '@chakra-ui/react';
import SelectTripResultItem from './SelectTripResultItem';

interface Filter {
	dateFrom?: String;
	dateTo?: String;
	carID?: String;
	driverID?: String;
	agencyID?: String;
}

interface Props {
	filters: Filter;
	onSelect: (tripID: string, driver: string | null) => any;
}

const SelectTripResults: React.FC<Props> = ({ filters, onSelect }) => {
	const { dateFrom, dateTo, carID, driverID, agencyID } = filters;
	const { loading, error, data, refetch } = useQuery(GET_TRIP_LIST, {
		variables: {
			dateFrom,
			dateTo,
			carID,
			driverID,
			agencyID
		}
	});
	if (!data || loading) return <>Loading...</>;
	return (
		<Fragment>
			<Flex flexFlow={'column'} gap={3}>
				{data.tripList.map((trip: any) => (
					<SelectTripResultItem
						key={trip._id}
						trip={trip}
						onSelect={onSelect}
					/>
				))}
			</Flex>
		</Fragment>
	);
};

export default SelectTripResults;
