import {
	Button,
	Container,
	Drawer,
	DrawerBody,
	DrawerContent,
	DrawerOverlay,
	Flex,
	Skeleton,
	Tooltip,
	useDisclosure
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import PageHeader from '../../components/pageHeader';
import { useQuery } from '@apollo/client';
import { GET_FLIGHTS } from '../../graphql/queries/GetFlights';
import { LuGlobe2, LuPlus } from 'react-icons/lu';
import Loading from '../../components/loading/Loading';
import { useNavigate } from 'react-router-dom';
import TableComponent from '../../components/table';
import { formatDate, parseDate } from '../../utils/parseDate';

const columns = [
	{ name: 'Kierowca', key: 'driver' },
	{ name: 'Linia', key: 'airLine' },
	{ name: 'Odlot', key: 'departureDate' },
	{ name: 'Lotnisko', key: 'airportD' },
	{ name: 'Przylot', key: 'arrivalDate' },
	{ name: 'Lotnisko', key: 'airportA' }
];

const Flights: React.FC = () => {
	const history = useNavigate();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { loading, error, data, refetch } = useQuery(GET_FLIGHTS);
	const [formattedData, setFormattedData] = useState<any[]>([]);
	useEffect(() => {
		refetch();
	}, []);
	useEffect(() => {
		if (data) {
			const arr: any[] = [];
			data.flights.forEach((flight: any) => {
				const arrivalDate: string = parseDate(flight.arrivalDate);
				const departureDate: string = parseDate(flight.departureDate);
				const item = {
					...flight,
					...{
						arrivalDate: formatDate(arrivalDate) + ` ${flight.arrivalTime}`,
						departureDate:
							formatDate(departureDate) + ` ${flight.departureTime}`,
						driver: flight.userID
							? `${flight.userID.firstName + ' ' + flight.userID.lastName}`
							: '-',
						airportD: (
							<Tooltip label={flight.airportFrom.name}>
								<Flex
									flexDirection={'row'}
									alignItems={'center'}
									gap={1}
									cursor={'pointer'}>
									{flight.airportFrom.country} <LuGlobe2 />
								</Flex>
							</Tooltip>
						),
						airportA: (
							<Tooltip label={flight.airportTo.name}>
								<Flex
									flexDirection={'row'}
									alignItems={'center'}
									gap={1}
									cursor={'pointer'}>
									{flight.airportTo.country} <LuGlobe2 />
								</Flex>
							</Tooltip>
						)
					}
				};
				arr.push(item);
			});
			setFormattedData(arr);
		}
	}, [data]);
	const addFlight = () => {
		history(`/flight/new`);
	};

	return (
		<Container maxW={'5xl'}>
			<PageHeader
				title="Loty"
				actions={
					<Button leftIcon={<LuPlus />} colorScheme="blue" onClick={addFlight}>
						Dodaj
					</Button>
				}
			/>
			<Skeleton isLoaded={!loading} height="80px">
				{loading ? (
					<Loading />
				) : (
					<TableComponent
						header={'Loty'}
						columns={columns}
						data={formattedData}
					/>
				)}
			</Skeleton>
			<Drawer isOpen={isOpen} onClose={onClose}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerBody></DrawerBody>
				</DrawerContent>
			</Drawer>
		</Container>
	);
};

export default Flights;
