import {
	Drawer,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerHeader,
	DrawerOverlay
} from '@chakra-ui/react';
import { useState } from 'react';
import SelectTripFilters from './SelectTripFilters';
import SelectTripResults from './SelectTripResults';

interface Props {
	isOpen: boolean;
	onClose: any;
	onSelect: (tripID: string, driverID?: string) => any;
}

interface Filter {
	dateFrom?: String;
	dateTo?: String;
	carID?: String;
	driverID?: String;
	agencyID?: String;
}

const SelectTrip: React.FC<Props> = ({ isOpen, onClose, onSelect }) => {
	const [filters, setFilters] = useState<boolean>(true);
	const [filtersValue, setFiltersValue] = useState<Filter>({
		dateFrom: '',
		dateTo: '',
		carID: '',
		driverID: '',
		agencyID: ''
	});
	const search = () => {
		setFilters(false);
	};
	const selectItem = (tripID: string, driver: string | null) => {
		if (driver) onSelect(tripID, driver);
		else onSelect(tripID);
	};
	return (
		<Drawer isOpen={isOpen} onClose={onClose}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton />
				<DrawerHeader>Znajdź Wyjazd</DrawerHeader>
				<DrawerBody>
					{filters ? (
						<SelectTripFilters
							filtersValue={filtersValue}
							setFiltersValue={setFiltersValue}
							search={search}
						/>
					) : (
						<SelectTripResults filters={filtersValue} onSelect={selectItem} />
					)}
				</DrawerBody>
			</DrawerContent>
		</Drawer>
	);
};

export default SelectTrip;
