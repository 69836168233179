const parseDate = (date: string | Date): string => {
	let dateFromDate =
		typeof date === 'string' ? new Date(Number(date)) : new Date(date);
	let monthDate = Number(dateFromDate.getMonth() + 1);
	let month = monthDate < 10 ? '0' + monthDate : monthDate;
	return (
		String(dateFromDate.getDate()).padStart(2, '0') +
		'.' +
		month +
		'.' +
		dateFromDate.getFullYear()
	);
};

const parseDateWithTime = (date: string | Date): string => {
	let dateFromDate =
		typeof date === 'string' ? new Date(Number(date)) : new Date(date);

	let monthDate = Number(dateFromDate.getMonth() + 1);
	let month = monthDate < 10 ? '0' + monthDate : monthDate;
	let hours = String(dateFromDate.getHours()).padStart(2, '0');
	let minutes = String(dateFromDate.getMinutes()).padStart(2, '0');

	return (
		String(dateFromDate.getDate()).padStart(2, '0') +
		'.' +
		month +
		'.' +
		dateFromDate.getFullYear() +
		' ' +
		hours +
		':' +
		minutes
	);
};

function parseStringToDate(dateString: string): Date | null {
	const parts = dateString.split('.');
	if (parts.length === 3) {
		const formattedDate = parts[2] + '-' + parts[1] + '-' + parts[0];
		return new Date(formattedDate);
	}
	return null; // Return null if the format is invalid
}

const formatDate = (inputDate: string): string => {
	const parts = inputDate.split('.');
	const formattedDate = parts[2] + '-' + parts[1] + '-' + parts[0];
	return formattedDate;
};

export { parseStringToDate, parseDate, formatDate, parseDateWithTime };
