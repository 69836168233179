import { Box, Flex, Icon, Divider } from '@chakra-ui/react';
import React from 'react';
import {
	LuBus,
	LuHome,
	LuLogOut,
	LuSettings,
	LuMapPin,
	LuCalendar,
	LuBuilding2,
	LuUsers,
	LuHotel,
	LuPlane,
	LuCheckCircle
} from 'react-icons/lu';
import { Link } from 'react-router-dom';
import LinkItem from './linkItem';

const Sections = [
	{
		name: 'Dashboard',
		icon: LuHome,
		href: '/',
		linkItems: []
	},
	{
		name: 'Kalendarz',
		icon: LuCalendar,
		href: '/trips/calendar',
		linkItems: []
	},
	{
		name: 'Wyjazdy',
		icon: LuMapPin,
		href: '/trips',
		linkItems: []
	},
	{
		name: 'Flota',
		icon: LuBus,
		href: '/fleet',
		linkItems: []
	},
	{
		name: 'Kierowcy',
		icon: LuUsers,
		href: '/drivers',
		linkItems: []
	},
	{
		name: 'Biura',
		icon: LuBuilding2,
		href: '/agency',
		linkItems: []
	},
	{
		name: 'Loty',
		icon: LuPlane,
		href: '/flights',
		linkItems: []
	}
	// {
	// 	name: 'Hotele',
	// 	icon: LuHotel,
	// 	href: '/agency',
	// 	linkItems: []
	// },
	// {
	// 	name: 'Checkpointy',
	// 	icon: LuCheckCircle,
	// 	href: '/agency',
	// 	linkItems: []
	// }
	// {
	// 	name: 'Wyjazdy',
	// 	icon: LuMapPin,
	// 	linkItems: [
	// 		{ name: 'Nowy wyjazd', href: '/trip/new', role: [] },
	// 		{ name: 'Lista wyjazdów', href: '/trips', role: [] },
	// 		{ name: 'Kalendarz', href: '/trips/calendar', role: [] }
	// 	]
	// },
	// {
	// 	name: 'Pojazdy',
	// 	icon: LuBus,
	// 	linkItems: [
	// 		{ name: 'Nowy pojazd', href: '/fleet/new', role: [] },
	// 		{ name: 'Lista pojazdów', href: '/fleet', role: [] }
	// 	]
	// },
	// {
	// 	name: 'Kierowcy',
	// 	icon: LuUsers,
	// 	linkItems: [
	// 		{ name: 'Nowy kierowca', href: '/drivers/new', role: [] },
	// 		{ name: 'Lista kierowców', href: '/drivers', role: [] }
	// 	]
	// },
	// {
	// 	name: 'Biura',
	// 	icon: LuBuilding2,
	// 	href: '/agency',
	// 	linkItems: [
	// 		{ name: 'Nowe biuro', href: '/agency/new', role: [] },
	// 		{ name: 'Lista biur', href: '/agency', role: [] }
	// 	]
	// }
	// {
	// 	name: 'Kontakty',
	// 	icon: LuBookMarked,
	// 	href: '/',
	// 	linkItems: [
	// 		{ name: 'Nowy kontakt', href: '/drivers/new', role: [] },
	// 		{ name: 'Lista kontaków', href: '/drivers', role: [] }
	// 	]
	// }
];

const Sidebar: React.FC = () => {
	return (
		<Box
			bg={'blue.900'}
			w={{ base: 'full', md: 64 }}
			pos="fixed"
			h="full"
			overflowY={'auto'}
			color={'#fff'}
			fontSize={'md'}
			display={{ base: 'none', md: 'block' }}>
			<Flex
				h={'100%'}
				px={6}
				py={8}
				flexFlow={'column'}
				justifyContent={'space-between'}
				color={'gray.50'}>
				<Flex flexFlow={'column'} gap={1}>
					{Sections.map((section, index) => {
						const href = section.href;
						return (
							<div key={index}>
								<Link to={href ?? ''}>
									<LinkItem icon={section.icon} name={section.name} />
								</Link>
							</div>
						);
					})}
				</Flex>
				<Flex flexFlow={'column'}>
					{/* <Link to={'/settings'}>
						<LinkItem name={'Ustawienia'} icon={LuSettings} />
					</Link> */}
					{/* <Link to={'/help'}>
						<LinkItem name={'Pomoc'} icon={LuHelpCircle} />
					</Link> */}
					{/* <Divider my={6} borderColor={'blue.200'} />
					<Flex
						align="center"
						py={2}
						px={3}
						borderRadius="lg"
						role="group"
						cursor="pointer"
						_hover={{
							bg: 'blue.500',
							color: 'white'
						}}>
						<Icon
							mr="4"
							fontSize="16"
							_groupHover={{
								color: 'white'
							}}
							as={LuLogOut}
						/>
						Wyloguj
					</Flex> */}
				</Flex>
			</Flex>
		</Box>
	);
};

export default Sidebar;
