import { Container } from '@chakra-ui/react';
import FormLayout from '../../layout/form';
import FlightForm from '../../components/forms/flight/flight';
import FormActions from '../../components/forms/actions';
import LuggageForm from '../../components/forms/flight/luggage';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, boolean } from 'yup';
import { useNavigate } from 'react-router-dom';
import { Flight, Luggage } from '../../types/Flight';
import NotesForm from '../../components/forms/flight/notes';
import CheckedInForm from '../../components/forms/flight/checkedIn';
import { useState } from 'react';
import FlightAirport from '../../components/forms/flight/airport';
import { ADD_FLIGHT } from '../../graphql/mutations/AddFlight';
import { useMutation } from '@apollo/client';

const defaultValues: Flight = {
	companyID: '',
	userID: '',
	tripID: '',
	flightNumber: '',
	airLine: '',
	departureDate: '',
	arrivalDate: '',
	airportFrom: {
		code: '',
		country: '',
		address: '',
		name: '',
		phone: ''
	},
	airportTo: {
		code: '',
		country: '',
		address: '',
		name: '',
		phone: ''
	},
	luggage: [],
	checkedIn: false,
	checkedInDate: '',
	notes: '',
	boardingPass: ''
};

const schema = object().shape({
	userID: string().required(),
	tripID: string().nullable(),
	flightNumber: string().required(),
	airLine: string().required(),
	departureDate: string().required(),
	departureTime: string().required(),
	arrivalDate: string().required(),
	arrivalTime: string().required(),
	checkedIn: boolean(),
	checkedInDate: string().nullable(),
	checkedInTime: string().nullable(),
	notes: string().nullable()
});

const FlightNew: React.FC = () => {
	const navigate = useNavigate();
	const [addFlight] = useMutation(ADD_FLIGHT);
	const [luggage, setLuggage] = useState<Luggage[]>([]);
	const [boardingPassFile, setFile] = useState<any>(null);
	const { register, handleSubmit, watch, setValue } = useForm<Flight | any>({
		defaultValues,
		resolver: yupResolver(schema),
		mode: 'onTouched' // default is "onSubmit"
	});
	const handleFileChange = (event: any) => {
		setFile(event.target.files[0]);
	};
	const cancel = () => {
		navigate('/flights');
	};
	const onSubmit = async (values: any) => {
		const dataToSave = { ...values };
		dataToSave.luggage = [...luggage];
		await addFlight({
			variables: dataToSave
		});
		// console.log('dataToSave', dataToSave);
		// if (dataToSave.checkedIn) {
		// 	console.log('boarding pass');
		// } else {
		// 	console.log('date for boarding pass');
		// }
	};
	return (
		<Container maxW={'5xl'} mb={7}>
			<form onSubmit={handleSubmit(onSubmit)}>
				<FormLayout title="Nowy Lot" subTitle="Wypełnij wymagane pola.">
					<FlightForm register={register} setValue={setValue} watch={watch} />
				</FormLayout>
				<FormLayout
					title="Wylot"
					subTitle="Wypełnij wymagane pola."
					// isDisabled={
					// 	watch('userID') === '' ||
					// 	watch('airLine') === '' ||
					// 	watch('flightNumber') === ''
					// }
					// fields="pasażer, linia lotnicza, numer lotu"
				>
					<FlightAirport
						register={register}
						setValue={setValue}
						type="departure"
					/>
				</FormLayout>
				<FormLayout title="Przylot" subTitle="Wypełnij wymagane pola.">
					<FlightAirport
						register={register}
						setValue={setValue}
						type="arrival"
					/>
				</FormLayout>
				<FormLayout title="Odprawa" subTitle="Wypełnij wymagane pola.">
					<CheckedInForm
						register={register}
						setValue={setValue}
						watch={watch}
						handleFileChange={handleFileChange}
						boardingPassFile={boardingPassFile}
					/>
				</FormLayout>
				<FormLayout title="Bagaż" subTitle="Wypełnij wymagane pola.">
					<LuggageForm luggage={luggage} setLuggage={setLuggage} />
				</FormLayout>
				<FormLayout title="Uwagi" subTitle="Wypełnij jeśli potrzeba.">
					<NotesForm register={register} />
				</FormLayout>
				<FormActions cancel={cancel} />
			</form>
		</Container>
	);
};

export default FlightNew;
