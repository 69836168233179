import {
	Card,
	CardBody,
	CardHeader,
	Table,
	Tbody,
	Td,
	Tr,
	CardFooter
} from '@chakra-ui/react';
import { ITable } from '../../types/Table';
import TableHead from './TableHead';
import { useEffect, useState } from 'react';
import TableSearch from './TabelSearch';
import TableActions from './TableActions';
import TablePagination from './TablePagination';

const itemsPerPage: number = 10;

const TableComponent = ({
	columns,
	data,
	onEdit,
	onDelete,
	onOpen
}: ITable) => {
	const [numberOfItems, setNumberOfItems] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [searchTerm, setSearchTerm] = useState('');
	const [tableData, setTableData] = useState<any[]>([]);
	const displayActions =
		typeof onDelete !== 'undefined' ||
		typeof onEdit !== 'undefined' ||
		typeof onOpen !== 'undefined';
	const search = () => {
		const keys = [] as any[];
		columns.forEach((column: any) => {
			if (!column.customField) keys.push(column.key);
		});
		const filteredData = data.filter((item: any) => {
			return keys.some((key: any) => {
				if (item[key])
					return item[key].toLowerCase().includes(searchTerm.toLowerCase());
			});
		});
		return filteredData;
	};
	const formatTableData = () => {
		let myData = searchTerm !== '' ? search() : [...data];
		setNumberOfItems(myData.length);
		const indexOfLastItem = currentPage * itemsPerPage;
		const indexOfFirstItem = indexOfLastItem - itemsPerPage;
		const currentItems = myData.slice(indexOfFirstItem, indexOfLastItem);
		setTableData([...currentItems]);
	};
	const nextPage = () => {
		const maxPage = Math.ceil(numberOfItems / 10);
		if (currentPage < maxPage) setCurrentPage(currentPage + 1);
	};
	const prevPage = () => {
		if (currentPage > 1) setCurrentPage(currentPage - 1);
	};
	useEffect(() => {
		formatTableData();
	}, [currentPage]);
	useEffect(() => {
		setCurrentPage(1);
		formatTableData();
	}, [data, searchTerm]);

	return (
		<Card mt={'32px'}>
			<CardHeader padding={0}>
				<TableSearch setSearchTerm={setSearchTerm} />
			</CardHeader>
			<CardBody padding={0}>
				<Table>
					<TableHead columns={columns} displayActions={displayActions} />
					<Tbody>
						{tableData.length === 0 && (
							<Tr>
								<Td
									colSpan={8}
									textAlign={'center'}
									fontWeight={'bold'}
									color={'gray.600'}>
									Brak danych
								</Td>
							</Tr>
						)}
						{tableData.map((el: any, index: number) => (
							<Tr key={index} _hover={{ bg: 'gray.50' }}>
								{columns.map((col, i) => (
									<Td
										key={i}
										onClick={() => console.log('click')}
										fontSize={i > 1 ? 'sm' : 'md'}>
										{el[col.key]}
									</Td>
								))}
								{displayActions ? (
									<TableActions
										el={el}
										onDelete={onDelete}
										onEdit={onEdit}
										onOpen={onOpen}
									/>
								) : (
									''
								)}
							</Tr>
						))}
					</Tbody>
				</Table>
			</CardBody>
			<CardFooter p={2}>
				<TablePagination
					numberOfItems={numberOfItems}
					currentPage={currentPage}
					prevPage={prevPage}
					nextPage={nextPage}
				/>
			</CardFooter>
		</Card>
	);
};

export default TableComponent;
