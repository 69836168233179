import React, { Fragment, useEffect, useRef } from 'react';
import { Flex, FormControl, FormLabel, Input, Button } from '@chakra-ui/react';
import { LuTicket } from 'react-icons/lu';

interface Props {
	register: any;
	setValue: any;
	watch: any;
	handleFileChange: (event: any) => any;
	boardingPassFile: any;
}

const getDayBefore = (dateString: string): string => {
	const date = new Date(dateString);
	date.setDate(date.getDate() - 1);
	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const day = String(date.getDate()).padStart(2, '0');
	return `${year}-${month}-${day}`;
};

const CheckedInForm: React.FC<Props> = ({
	register,
	setValue,
	watch,
	handleFileChange,
	boardingPassFile
}) => {
	const inputFileRef = useRef<HTMLInputElement>(null);
	const initUpload = () => {
		if (inputFileRef.current) inputFileRef.current.click();
	};
	const departureDate = watch('departureDate');
	const departureTime = watch('departureTime');
	//const checkedInDate = watch('checkedInDate');
	const checkedInTime = watch('checkedInTime');
	useEffect(() => {
		if (departureDate !== '') {
			const previousDay = getDayBefore(departureDate);
			setValue('checkedInDate', previousDay);
		}
	}, [departureDate]);
	useEffect(() => {
		if (checkedInTime === '') setValue('checkedInTime', departureTime);
	}, [departureTime]);
	return (
		<Fragment>
			<Flex flexFlow={'column'} gap={5}>
				<FormControl>
					<FormLabel>Odprawiono</FormLabel>
					<Flex gap={1}>
						<Flex
							h={'full'}
							px={5}
							py={2}
							borderRadius={4}
							justifyContent={'center'}
							alignItems={'center'}
							border={'1px solid'}
							borderColor={'gray.300'}
							cursor={'pointer'}
							color={watch('checkedIn') ? 'white' : 'black'}
							bg={watch('checkedIn') ? 'blue.500' : 'white'}
							onClick={() => setValue('checkedIn', true)}>
							TAK
						</Flex>
						<Flex
							h={'full'}
							p={5}
							py={2}
							borderRadius={4}
							justifyContent={'center'}
							alignItems={'center'}
							border={'1px solid'}
							borderColor={'gray.300'}
							cursor={'pointer'}
							color={!watch('checkedIn') ? 'white' : 'black'}
							bg={!watch('checkedIn') ? 'blue.500' : 'white'}
							onClick={() => setValue('checkedIn', false)}>
							NIE
						</Flex>
					</Flex>
				</FormControl>
				{watch('checkedIn') ? (
					<FormControl mt={3} display={'none'}>
						<FormLabel>Karta pokładowa</FormLabel>
						<Flex gap={4}>
							<Flex>
								<Button
									leftIcon={<LuTicket />}
									colorScheme="blue"
									variant={'solid'}
									onClick={initUpload}>
									{boardingPassFile ? 'Zmień' : 'Dodaj'} kartę pokładową
								</Button>
								<input
									type="file"
									ref={inputFileRef}
									onChange={handleFileChange}
									style={{ display: 'none' }}
								/>
							</Flex>
							{boardingPassFile ? (
								<Flex>
									<Button colorScheme="red" variant={'ghost'}>
										Usuń plik
									</Button>
								</Flex>
							) : (
								''
							)}
						</Flex>
					</FormControl>
				) : (
					<FormControl>
						<FormLabel>Data i godzina odprawy</FormLabel>
						<Flex gap={3}>
							<Input type="date" {...register('checkedInDate')} />
							<Input type="time" {...register('checkedInTime')} />
						</Flex>
					</FormControl>
				)}
			</Flex>
		</Fragment>
	);
};

export default CheckedInForm;
